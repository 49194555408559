function HelpModal(props) {

  // Define variables
  const closeModal = props.closeModal;


  return (
    <div class='modal-wrapper'>
      <div class='modal-container'>
        <div class='flex-row' style={{alignItems: 'flex-start'}}>
          <div class='help-modal-inner'>

            <div class='flex-row'>
              <span class='help-command'>enter</span>
              <span class='help-description'>Creates a new row below the current row you are in</span>
            </div>

            <div class='flex-row'>
              <span class='help-command'>cmd + ]</span>
              <span class='help-description'>Indent row right</span>
            </div>

            <div class='flex-row'>
              <span class='help-command'>cmd + [</span>
              <span class='help-description'>Indent row left</span>
            </div>

            <div class='flex-row'>
              <span class='help-command'>cmd + up arrow</span>
              <span class='help-description'>Move row up one line within the day</span>
            </div>

            <div class='flex-row'>
              <span class='help-command'>cmd + down arrow</span>
              <span class='help-description'>Move row down one line within the day</span>
            </div>

            <div class='flex-row'>
              <span class='help-command'>Trash can icon</span>
              <span class='help-description'>Deletes the highlighted row ( will only delete a row without content )</span>
            </div>

            <div class='flex-row'>
              <span class='help-command'>Push button</span>
              <span class='help-description'>Push to the next day</span>
            </div>

            <p style={{lineHeight: '1.5rem'}}>
              <br />
              <br />
              <br />
              <strong style={{marginBottom: '5px'}}>Need help? Have a suggestion?</strong>
              <br />
              Contact support at <a href="mailto:diastandup@gmail.com">diastandup@gmail.com</a>
            </p>

          </div>
          <button
            type='button'
            class='button'
            onClick={() => closeModal()}
          >Close</button>
        </div>
      </div>
    </div>
  )
}

export default HelpModal;