import React from 'react';
// import { Steps, Hints } from 'intro.js-react';
import { Steps } from 'intro.js-react';
import 'intro.js/introjs.css';


function Tour() {

  const stepOne = (
    <div>
      <h3>Welcome!</h3>
      <p>The standup app helps you keep track of your daily tasks and conduct your daily standup.</p>
      <br />
      <p>Let's take a quick tour</p>
    </div>
  )

  const stepTwo = (
    <div>
      <h3>Today</h3>
      <p>Focus on your tasks for today. Add new tasks as they come up and check off the tasks you complete.</p>
    </div>
  )

  const stepThree = (
    <div>
      <h3>Didn't finish something?</h3>
      <p>Push it to the next day.</p>
    </div>
  )

  const stepFour = (
    <div>
      <h3>Plan Ahead</h3>
      <p>Keep track of tasks you want to do later this week by adding tasks ahead of time.</p>
    </div>
  )

  const stepFive = (
    <div>
      <h3>View the Standup</h3>
      <p>Your standup is compiled so it's easy to read. Copy and paste it wherever you chat with your team members.</p>
    </div>
  )

  const stepSix = (
    <div>
      <h3>Need Help?</h3>
      <p>See the keyboard shortcuts that allow you to move around the app with ease.</p>
    </div>
  )

  const steps = [
    {
      intro: stepOne
    },
    {
      element: '#today',
      intro: stepTwo
    },
    // {
    //   // element: '#today .item-action-button-secondary.push-button',
    //   element: '#today .day-tasks',
    //   intro: stepThree
    // },
    {
      element: '#tomorrow',
      intro: stepFour
    }
    ,
    {
      element: '#view-daily-standup',
      intro: stepFive
    }
    ,
    {
      element: '.left-column .contact-support-container',
      intro: stepSix
    }
  ];

  function onExit() {
    console.log('onExit');
  }

  const options = {
    overlayOpacity: .25,
    doneLabel: "Let's Go!"
  }

  return (
    <div class='welcome-tour-wrapper'>
      <Steps
        enabled={true}
        steps={steps}
        initialStep={0}
        onExit={onExit}
        options={options}
      />
    </div>
  );
}

export default Tour;