import { useState, useEffect } from "react";

import { IoClose } from "react-icons/io5";
import DatePicker from "react-datepicker";
import SelectLabels from "./selectLabels";

import "react-datepicker/dist/react-datepicker.css";



function EditModal(props) {

  // Define variables
  const [body, setBody] = useState('');
  const [dueDate, setDueDate] = useState(null);
  const [labels, setLabels] = useState([]);
  const closeEditModal = props.closeEditModal;
  const item = props.item;
  const handleDetailChange = props.handleDetailChange;
  const dayIndex = props.dayIndex;
  const itemIndex = props.itemIndex;


  // Component load actions
  useEffect(() => {

    setBody(item.body);

    if( item.dueDate ) {
      const dateObject = new Date(item.dueDate);
      setDueDate(dateObject);
    }

    if( item.labels ) {
      setLabels(item.labels);
    }

  }, []);


  // Define functions
  function handleChange(stateMethod, value, fieldName) {

    if( fieldName === 'dueDate' ) {
      value = value.toLocaleDateString('en-us', { year:"numeric", month:"long", day:"numeric"});
    }

    item[fieldName] = value;


    stateMethod(value);

    // TO DO - put a slight delay / interval so we don't tax the system
    handleDetailChange(item, dayIndex, itemIndex, fieldName);
  }


  return (
    <div class='modal-wrapper edit-modal-wrapper'>
      <div class='modal-container'>

        <div class='flex-row' style={{justifyContent: 'space-between', marginBottom: '30px'}}>
          <h3>Task Details</h3>
          <button onClick={() => closeEditModal()}>
            <IoClose color={'#696969'} size={'1.3rem'} />
          </button>
        </div>

        <div>

          <div>
            <div class='edit-row-item'>
              <label>Description</label>
              <input
                type='text'
                onChange={(event) => handleChange(setBody, event.target.value, 'body')}
                value={body}
              />
            </div>
          </div>

          <div class='flex-row edit-row'>

            <div class='edit-row-item'>
              <label>Due Date</label>
              <DatePicker selected={dueDate} onChange={(date) => handleChange(setDueDate, date, 'dueDate')} />
            </div>

            <SelectLabels
              labels={labels}
              setLabels={setLabels}
              handleChange={handleChange}
            />

          </div>
        </div>

      </div>
    </div>
  );
}

export default EditModal;