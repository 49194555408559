import React from 'react';
import { useState, useEffect } from 'react';

import {arrayMoveImmutable} from 'array-move';
import apiCall from '../utilities/auth/apiCall';
import { getDashboard, updateUserTask } from '../utilities/auth/authApi';
import LeftColumn from '../utilities/layout/leftColumn';
import TopBar from '../utilities/layout/topBar';
import Day from './day';
import FormattedStandup from './formattedStandup';
import LoadingBasic from '../utilities/loadingBasic';
import Tour from './tour';

let interval;


function DashboardScreen() {

  // Define variables
  const [loading, setLoading] = useState(<LoadingBasic />);
  const [itemsList, setItemsList] = useState([]);
  const [standup, setStandup] = useState(null);
  const [formattedStandup, setFormattedStandup] = useState(null);
  const [showTour, setShowTour] = useState(false);


  // Component load actions
  useEffect(() => {

    tryShowTour();

    apiCall(getDashboard, {}).then((response) => {
      if( response.success ) {

        setItemsList(response.data);
        buildStandup(response.data);
        setLoading(null);

        setTimeout(() => {
          scrollToToday();
        }, 300);

      } else {
        console.log('error -> ', response.error);
      }

    });

  }, []);


  // Define functions
  function tryShowTour() {
    const isNewAccount = localStorage.getItem("isNewAccount");

    if( isNewAccount ) {
      setShowTour(true);
      localStorage.removeItem("isNewAccount");
    }
  }

  function scrollToToday() {
    document.getElementById('today')?.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    });
  }

  function handleInputChange(value, dayIndex, itemIndex, dateObject, itemType) {
    clearInterval(interval);

    // thisDayIndex = dayIndex;
    const items = JSON.parse(JSON.stringify(itemsList));
    const item = ( itemType === 'blocker' ) ? items[dayIndex].blockers[itemIndex] : items[dayIndex].items[itemIndex];
    item.body = value;

    if( !item.dayName ) {
      item.dayName = dateObject.dayName;
      item.dayNumber = dateObject.dayNumber;
      item.monthName = dateObject.monthName;
    }

    setItemsList(items);

    interval = setInterval(function(){
      autoSave(dayIndex);
    }, 1400);

  }

  function handleDetailChange(newItem, dayIndex, itemIndex, fieldName) {
    clearInterval(interval);

    const items = JSON.parse(JSON.stringify(itemsList));
    const item = items[dayIndex].items[itemIndex];
    item[fieldName] = newItem[fieldName];

    setItemsList(items);
    interval = setInterval(function(){
      autoSave(dayIndex);
    }, 1400);
  }

  function autoSave(dayIndex) {
    clearInterval(interval);

    const dayElementId = `day-${dayIndex}`;
    const autosaveButton = document.querySelector(`#${dayElementId} .autosave-button`);
    autosaveButton.click();
  }

  function handleKeyDown(event, dayIndex, itemIndex, itemType) {

    if( event.code === 'Enter' ) {
      event.preventDefault();
    }

    if( event.metaKey && event.code === 'BracketRight' ) {
      event.preventDefault();
      indentItem(dayIndex, itemIndex, 'right', itemType);
    }

    if( event.metaKey && event.code === 'BracketLeft' ) {
      event.preventDefault();
      indentItem(dayIndex, itemIndex, 'left', itemType);
    }

    if( event.metaKey && event.code === 'ArrowUp' ) {
      event.preventDefault();
      moveItem(dayIndex, itemIndex, 'up', itemType);
    }

    if( event.metaKey && event.code === 'ArrowDown' ) {
      event.preventDefault();
      moveItem(dayIndex, itemIndex, 'down', itemType);
    }

  }

  function handleKeyUp(event, dayIndex, itemIndex, itemType) {
    if( event.code === 'Enter' ) {
      addNewRow(dayIndex, itemIndex, itemType);
    }

  }

  function addNewRow(dayIndex, itemIndex, itemType) {
    const items = JSON.parse(JSON.stringify(itemsList));
      const day = items[dayIndex];
      const dayItems = ( itemType === 'blocker' ) ? day.blockers : day.items;

      const insertAt = itemIndex + 1;

      const newDayItems = [
        // Items before the insertion point:
        ...dayItems.slice(0, insertAt),
        // New item:
        { body: '', complete: false, intentIndex: 0 },
        // Items after the insertion point:
        ...dayItems.slice(insertAt)
      ];

      if( itemType === 'blocker' ) {
        day.blockers = newDayItems;
      } else {
        day.items = newDayItems;
      }

      setItemsList(items);

      // Auto foucs
      setTimeout(() => {
        const dayItemElementId = `day-${itemType}-${dayIndex}-${itemIndex+1}`;
        const newInput = document.querySelector(`#${dayItemElementId} textarea`);
        newInput.focus();
      }, 100);
  }

  function indentItem(dayIndex, itemIndex, direction, itemType) {

    if( itemIndex === 0 && direction === 'right' ) {
      return false;
    }

    const items = JSON.parse(JSON.stringify(itemsList));
    const item = ( itemType === 'blocker' ) ? items[dayIndex].blockers[itemIndex] : items[dayIndex].items[itemIndex];
    let currentIndentIndex = item.intentIndex;
    if( currentIndentIndex === undefined ) { currentIndentIndex = 0 }
    const newIndentIndex = (direction === 'right') ? currentIndentIndex + 1 : currentIndentIndex - 1;

    item.intentIndex = newIndentIndex;
    setItemsList(items);

    setTimeout(() => {
      autoSave(dayIndex);
    }, 100);
  }

  function moveItem(dayIndex, itemIndex, direction, itemType) {

    if( itemIndex === 0 && direction === 'up' ) {
      return false;
    }

    const items = JSON.parse(JSON.stringify(itemsList));
    // const dayItems = items[dayIndex].items;
    const day = items[dayIndex];
    const dayItems = ( itemType === 'blocker' ) ? day.blockers : day.items;

    if( itemIndex === (dayItems.length - 1) && direction === 'down' ) {
      return false;
    }

    const newItemIndex = direction === 'up' ? itemIndex - 1 : itemIndex + 1;
    const newDayItems = arrayMoveImmutable(dayItems, itemIndex, newItemIndex);

    if( itemType === 'blocker' ) {
      items[dayIndex].blockers = newDayItems;
    } else {
      items[dayIndex].items = newDayItems;
    }
    setItemsList(items);

    setTimeout(() => {
      autoSave(dayIndex);

      const dayItemElementId = `day-${itemType}-${dayIndex}-${newItemIndex}`;
      const newInput = document.querySelector(`#${dayItemElementId} textarea`);
      newInput.focus();

    }, 100);
  }

  function deleteItem(dayIndex, itemIndex, itemType) {
    const items = JSON.parse(JSON.stringify(itemsList));
    // const dayItems = items[dayIndex].items;
    const day = items[dayIndex];
    const dayItems = ( itemType === 'blocker' ) ? day.blockers : day.items;

    if( dayItems.length === 1 ) {
      return false;
    }

    dayItems.splice(itemIndex, 1);

    setTimeout(() => {
      setItemsList(items);
      setTimeout(() => autoSave(dayIndex), 50);
    }, 50);
  }

  function pushToNextDay(dayIndex, itemIndex) {
    const items = JSON.parse(JSON.stringify(itemsList));
    const dayItems = items[dayIndex].items;
    const item = dayItems[itemIndex];

    const tomorrowDayIndex = dayIndex - 1;
    const tomorrowDayItems = items[tomorrowDayIndex].items;

    tomorrowDayItems.push(item);
    dayItems.splice(itemIndex, 1);

    // Ensure we didn't remove the only row from that day
    if( dayItems.length === 0 ) {
      dayItems.push( {body: '', complete: false} );
    }

    setItemsList(items);

    setTimeout(() => {
      autoSave(dayIndex);
      autoSave(tomorrowDayIndex);
    }, 100);

  }

  function handleCheckboxChange(dayIndex, itemIndex) {
    const items = JSON.parse(JSON.stringify(itemsList));
    const item = items[dayIndex].items[itemIndex];
    if( item?.body?.length > 0 ) {
      const complete = item.complete ? false : true;

      item.complete = complete;
      setItemsList(items);

      setTimeout(() => {
        autoSave(dayIndex);
      }, 100);
    }
  }


  function updateContent(dayIndex) {

    const payload = itemsList[dayIndex];

    apiCall(updateUserTask, payload).then((response) => {
      if( response.success ) {
        // setLineItem(response.data)

        buildStandup(itemsList);
      } else {
        // toast.error(response.error);
        console.log('error -> ', response.error);
      }
    });
  }

  function buildStandup(tasksArray) {

    const todayIndex = tasksArray.findIndex((object) => object.today);
    const today = tasksArray[todayIndex];

    let yesterday;
    let yesterdayTitle = 'Yesterday';
    let weekend = [];

    const indexOneDayBack = todayIndex + 1;
    const indexTwoDaysBack = todayIndex + 2;
    const indexThreeDaysBack = todayIndex + 3;

    const oneDayBack = tasksArray[indexOneDayBack];
    if( oneDayBack?.weekend ) {

      const twoDaysBack = tasksArray[indexTwoDaysBack];

      if( oneDayBack.items.length > 0 && oneDayBack.items[0].body !== '' ) {
        weekend = weekend.concat(oneDayBack.items);
      }

      if( twoDaysBack.weekend ) {

        yesterday = tasksArray[indexThreeDaysBack] || {items: []};
        yesterdayTitle = 'Friday';
        // weekend = weekend.concat(twoDaysBack.items);

        if( twoDaysBack.items.length > 0 && twoDaysBack.items[0].body !== '' ) {
          weekend = weekend.concat(twoDaysBack.items);
        }

      } else {
        yesterday = twoDaysBack || {items: []};
      }

    } else {
      yesterday = oneDayBack || {items: []};
    }

    // const yesterday = tasksArray[todayIndex + 1] || {items: []};

    const todayContent = buildList({title: 'Today', items: today.items});
    const blockerContent = buildList({title: 'Blockers', items: today.blockers});
    const yesterdayContent = buildList({title: yesterdayTitle, items: yesterday.items});


    let weekendContent;
    if( weekend && weekend.length > 0 ) {
      weekendContent = buildList({title: 'Weekend', items: weekend});
    }


    const content = (
      <div>
        {yesterdayContent}
        {weekendContent}
        {todayContent}
        {blockerContent}
      </div>

    );

    setStandup(content);
  }

  function openStandupModal() {
    setFormattedStandup(
      <FormattedStandup
        standup={standup}
        closeModal={closeStandupModal}
      />
    );
  }

  function closeStandupModal() {
    setFormattedStandup(null);
  }


  // Screen components
  let tourElement;
  if( showTour ) {
    tourElement = <Tour />;
  }


  return (
    <div class='wrapper'>
      {formattedStandup}
      {tourElement}
      <LeftColumn />


      <div class='right-column'>

        <TopBar
          openStandupModal={openStandupModal}
          scrollToToday={scrollToToday}
          searchType={'dashboard'}
          itemsList={itemsList}
        />

        <section class='body-content'>
          <div class='left-bar'>

            {loading}
            {itemsList.map((day, dayIndex) =>
              <Day
                key={`day-${dayIndex}`}
                day={day}
                dayIndex={dayIndex}
                handleInputChange={handleInputChange}
                handleDetailChange={handleDetailChange}
                handleKeyUp={handleKeyUp}
                handleKeyDown={handleKeyDown}
                addNewRow={addNewRow}
                indentItem={indentItem}
                moveItem={moveItem}
                pushToNextDay={pushToNextDay}
                deleteItem={deleteItem}
                handleCheckboxChange={handleCheckboxChange}
                updateContent={updateContent}
              />
            )}
          </div>

        </section>

      </div>
    </div>
  );
}

export default DashboardScreen;

// --

function buildList(listObject) {

  const title = listObject.title;
  const items = listObject.items;

  const contentArray = [];

  const dotOne = <span class='dot'>&#9679;</span>;
  const dotTwo = <span class='dot'>&#9675;</span>;
  const dotThree = <span class='dot'>&#9632;</span>;
  const dotFour = <span class='dot'>&#9671;</span>;
  const dotFive = <span class='dot'>&#9650;</span>;

  const indentOne = <span>&nbsp;&nbsp;&nbsp;</span>;
  const indentTwo = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
  const indentThree = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
  const indentFour = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;
  const indentFive = <span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>;

  items.map((item, index) => {
    if( item.body ) {
      const intentIndex = item.intentIndex;
      let indent = indentOne;
      let dot = dotOne;

      if( intentIndex && intentIndex >> 0 ) {

        if( intentIndex === 1 ) {
          dot = dotTwo;
          indent = indentTwo;
        } else if ( intentIndex === 2 ) {
          dot = dotThree;
          indent = indentThree;
        } else if ( intentIndex === 3 ) {
          dot = dotFour;
          indent = indentFour;
        } else if ( intentIndex === 4 ) {
          dot = dotFive;
          indent = indentFive;
        }

      }

      const key = `bullet-${index}`;
      contentArray.push(<div key={key}>{indent}{dot}&nbsp;&nbsp; {item.body}</div>);
    }
  });


  let titleElement = <p><strong>{title}</strong></p>;
  if( title === 'Blockers' && contentArray.length === 0 ) {
    titleElement = <p><strong>{title}:</strong> None</p>
  }

  const block = (
    <div>
      {titleElement}
      {contentArray}
      <br /><br />
    </div>
  );

  return block;
}