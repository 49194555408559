import { useState } from "react";

import { IoEllipsisHorizontalCircle } from "react-icons/io5";
import EditMenu from "./editMenu";
import EditModal from "./editModal";


function EditTaskButton(props) {

  // Define variables
  const [menuElement, setMenuElement] = useState(null);
  const [editModalElement, setEditModalElement] = useState(null);
  const item = props.item;
  const dayIndex = props.dayIndex;
  const itemIndex = props.itemIndex;
  const itemType = props.itemType;
  const complete = props.complete;
  const today = props.today;
  const itemBodyLength = item.body.length;
  const pushToNextDay = props.pushToNextDay;
  const deleteItem = props.deleteItem;
  const handleDetailChange = props.handleDetailChange;
  const canPushToNextDay = (!complete && pushToNextDay && itemBodyLength > 0);
  const canDeleteItem = itemIndex !== 0;


  // Define functions
  function toggleMenu() {
    if( menuElement ) {
      closeMenu();
    } else {
      setMenuElement(
        <EditMenu
          openEditModal={openEditModal}
          handleNextDayPush={handleNextDayPush}
          handleDeletePush={handleDeletePush}
          canPushToNextDay={canPushToNextDay}
          canDeleteItem={canDeleteItem}
          closeMenu={closeMenu}
          bodyLength={itemBodyLength}
          today={today}
        />
      );
    }
  }

  function closeMenu() {
    // Have to delay slightly so the rest of the functions can process
    setTimeout(() => {
      setMenuElement(null);
    }, 100);
  }

  function handleNextDayPush() {
    pushToNextDay(dayIndex, itemIndex);
  }

  function handleDeletePush() {
    deleteItem(dayIndex, itemIndex, itemType);
  }

  function openEditModal() {
    closeMenu();

    setEditModalElement(
      <EditModal
        closeEditModal={closeEditModal}
        item={item}
        handleDetailChange={handleDetailChange}
        dayIndex={dayIndex}
        itemIndex={itemIndex}
      />
    );
  }

  function closeEditModal() {
    setEditModalElement(null);
    closeMenu();
  }


  return (
    <div>
      {editModalElement}
      <div class='edit-button-container'>
        <button
          type='button'
          class='edit-button'
          onClick={() => toggleMenu()}
        >
          <IoEllipsisHorizontalCircle color={'#696969'} size={'1.3rem'} />
        </button>

        {menuElement}
      </div>
    </div>
  );
}

export default EditTaskButton;